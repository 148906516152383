import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from 'services/theme'
import { Grid } from 'react-styled-flexboxgrid'
import { H1 } from 'components/primitives/Text'
import icon from 'assets/icon.png'
import {
  RowView,
  CenteredRowView,
  CenteredColumnView
} from 'components/primitives/Views'
import { Link } from '@reach/router'
import { Download } from './Download'

export const Navbar: React.FC = () => {
  const [show, setShow] = useState(true)

  React.useEffect(() => {
    if (typeof window === 'undefined') return

    let last = 0
    const handler = () => {
      const pos = window.pageYOffset || document.documentElement.scrollTop

      if (last > 50) {
        const shouldShow = pos < last
        setShow(shouldShow)
      } else {
        setShow(true)
      }

      last = pos
    }

    window.addEventListener('scroll', handler)
    return () => window.removeEventListener('scroll', handler)
  }, [])

  return (
    <Wrapper show={show}>
      <Grid>
        <RowView my='2.25rem'>
          <CenteredColumnView>
            <Link to='/'>
              <RowView>
                <img src={icon} height={30} />
                <H1 fontSize='2.6rem' ml='tiny' color='textAccent1'>
                  Dose
                </H1>
              </RowView>
            </Link>
          </CenteredColumnView>
          <div style={{ marginLeft: 'auto' }}>
            <Download place='header' useIcons />
          </div>
        </RowView>
      </Grid>
    </Wrapper>
  )
}

export const NAVBAR_SIZE = '8rem'

interface WrapperProps {
  show: boolean
}

const Wrapper = styled(CenteredRowView)<WrapperProps>`
  background: ${colors.bg};
  height: ${NAVBAR_SIZE};
  left: 0;
  position: fixed;
  top: 0;
  transition: 0.3s all;
  width: 100vw;
  z-index: 100;

  ${props =>
    !props.show &&
    `
    top: -${NAVBAR_SIZE};
  `}
`
