import React from 'react'
import styled from 'styled-components'
import AppStore from 'assets/buttons/app-store-badge.svg'
import PlayStore from 'assets/buttons/google-play-badge.png'
import AppleIcon from 'assets/icons/fa/apple-brands.svg'
import PlayIcon from 'assets/icons/fa/google-play-brands.svg'
import { FullCenterView } from 'components/primitives/Views'
import { Box } from 'components/primitives/Box'
import { LANDING, GA } from 'services/analytics'
import { media } from 'components/primitives/Responsive'
import { colors } from 'services/theme'
import { Text } from 'components/primitives/Text'

interface Props {
  place: string
  useIcons?: boolean
}

const LINKS = {
  ios: 'https://apps.apple.com/us/app/dose/id1514087697',
  android: 'https://play.google.com/store/apps/details?id=io.doseapp.android'
}

const ICON_PROPS = {
  height: 20,
  className: 'icon'
}

export const Download: React.FC<Props> = ({ place, useIcons }) => {
  const open = (system: 'ios' | 'android') => {
    GA.event({
      category: LANDING,
      action: 'GetApp',
      label: `${place}-${system}`
    })
    window.open(LINKS[system])
  }

  return (
    <Container>
      {useIcons && (
        <Text color='textAccent1' mr='small' fontSize='small' mt={-8}>
          Get now
        </Text>
      )}
      <DownloadButton marginRight='small' onClick={() => open('ios')}>
        {useIcons ? (
          <AppleIcon {...ICON_PROPS} height={25} style={{ marginTop: -3 }} />
        ) : (
          <AppStore />
        )}
      </DownloadButton>

      <DownloadButton onClick={() => open('android')}>
        {useIcons ? (
          <PlayIcon {...ICON_PROPS} />
        ) : (
          <img src={PlayStore} height={58} title='Get it on Google Play' />
        )}
      </DownloadButton>
    </Container>
  )
}

const Container = styled(FullCenterView)`
  ${media.lg} {
    max-width: 270px;
  }
`

const DownloadButton = styled(Box)`
  cursor: pointer;
  transition-duration: 0.2s;

  svg.icon {
    fill: ${colors.textAccent1};
  }

  :hover {
    transform: scale(1.05);

    svg.icon {
      fill: ${colors.text};
    }
  }
`
