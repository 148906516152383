import React, { useContext } from 'react'
import styled from 'styled-components'
import { Box } from 'components/primitives/Box'
import { H1, Text } from 'components/primitives/Text'
import { Section } from 'components/primitives/Section'
import Cross from 'assets/icons/fa/times-regular.svg'
import { colors } from 'services/theme'
import { ModalContext } from '../shared/Page'
import { Transition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'

export const GetModal: React.FC = () => {
  const { active, close } = useContext(ModalContext)

  const handleBackgroundClick = (e: React.MouseEvent) =>
    e.target === e.currentTarget && close()

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => e.key === 'Escape' && close()

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <Transition
      in={active}
      timeout={{ enter: 1, exit: 300 }}
      unmountOnExit
      mountOnEnter
    >
      {state => (
        <Background onClick={handleBackgroundClick} state={state}>
          <Wrapper bg='bg' mx='auto' py='big' px='small'>
            <Close height='2rem' fill={colors.textAccent2} onClick={close} />
            <Section style={{ textAlign: 'center' }}>
              <H1>Get the app</H1>
              <Text>Start your new, healthy lifestyle</Text>
            </Section>
          </Wrapper>
        </Background>
      )}
    </Transition>
  )
}

interface BgProps {
  state: TransitionStatus
}

const Background = styled(Box)<BgProps>`
  background: rgba(32, 32, 32, 0.5);
  bottom: 0;
  left: 0;
  opacity: ${props => (props.state === 'entered' ? 1 : 0)};
  position: fixed;
  right: 0;
  top: 0;
  transition-duration: 0.3s;
  z-index: 1000;
`

const Wrapper = styled(Box)`
  max-width: 45rem;

  @media screen and (max-width: 45rem) {
    height: 100%;
    overflow-y: auto;
    width: 100%;
  }

  @media screen and (min-width: 45rem) {
    margin-top: 20vh;
    border-radius: 1rem;
  }
`

const Close = styled(Cross)`
  cursor: pointer;
  float: right;
  position: relative;
  right: 1.5rem;
  top: -2rem;
  zindex: 10000;
`
