/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { themes } from 'services/theme'
import { GlobalStyles } from 'components/shared/GlobalStyles'
import { Navbar, NAVBAR_SIZE } from './Navbar'
import { Box } from 'components/primitives/Box'
import { GA, LANDING } from 'services/analytics'
import { GetModal } from '../Home/GetModal'
import { Footer } from './Footer'
import { ColumnView } from 'components/primitives/Views'

export const ModalContext = React.createContext({
  active: false,
  open: (origin: string) => console.log(origin),
  close: () => {}
})

export const Page: React.FC = ({ children }) => {
  const [modalActive, setModalActive] = useState(false)

  const openModal = (origin: string) => {
    setModalActive(true)
    GA.event({
      category: LANDING,
      action: 'GetApp',
      label: origin
    })
  }

  const modalContext = {
    active: modalActive,
    open: openModal,
    close: () => setModalActive(false)
  }

  return (
    <ThemeProvider theme={themes.light}>
      <ModalContext.Provider value={modalContext}>
        <GlobalStyles />

        <ColumnView style={{ height: '100vh' }}>
          <Navbar />
          <Box mt={NAVBAR_SIZE} flex={1}>
            <GetModal />

            {children}
          </Box>
          <Footer />
        </ColumnView>
      </ModalContext.Provider>
    </ThemeProvider>
  )
}
