import styled, { CSSProperties } from 'styled-components'
import {
  space,
  color,
  SpaceProps,
  ColorProps,
  shadow,
  ShadowProps,
  layout,
  LayoutProps,
  FlexboxProps,
  flexbox
} from 'styled-system'

type BoxProps = SpaceProps &
  ColorProps &
  ShadowProps &
  LayoutProps &
  FlexboxProps

export const Box = styled.div<BoxProps>`
  box-sizing: border-box;
  ${space}
  ${color}
  ${shadow}
  ${layout}
  ${flexbox}
`

Box.defaultProps = {
  bg: 'none'
}

type RoundedBoxProps = { radius?: string }
export const RoundedBox = styled(Box)<RoundedBoxProps>`
  border-radius: ${props => props.radius || '1.5rem'};
`

RoundedBox.defaultProps = {
  bg: 'var(--card-bg)'
}

export const BLOCKING_STYLE: CSSProperties = {
  zIndex: 10000,
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
}
