import {
  trackCustomEvent,
  CustomEventArgs
} from 'gatsby-plugin-google-analytics'
import { isIOS, isAndroid, isTiny } from './device'
import { AndroidInterface } from 'native/android'
declare let Android: AndroidInterface

export const APP = 'App'
export const DOSES = 'Doses'
export const SUBSTANCES = 'Substances'
export const SETTINGS = 'Settings'
export const LANDING = 'Landing'

export const GA = {
  event: (args: CustomEventArgs) => {
    if (process.env.NODE_ENV === 'development') console.log('GA', args)

    if (typeof Android !== 'undefined' && Android.logEvent) {
      Android.logEvent(
        args.action || '',
        args.category || '',
        args.label || '',
        args.value || 0
      )
    }

    trackCustomEvent(args)
  }
}

export const registerPageview = () => {
  //
}

export const registerLaunch = () => {
  const label = [isIOS() && 'ios', isAndroid() && 'android', isTiny() && 'tiny']
    .filter(Boolean)
    .join()

  GA.event({
    category: APP,
    action: 'Launch',
    label
  })
}
