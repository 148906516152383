import React from 'react'
import { Box } from 'components/primitives/Box'
import { Grid } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import { Link } from '@reach/router'
import { space, fontWeights, colors } from 'services/theme'
import { Text } from 'components/primitives/Text'
import Twitter from 'assets/icons/fa/twitter-brands.svg'
import Instagram from 'assets/icons/fa/instagram-brands.svg'
import { CenteredRowView } from 'components/primitives/Views'

export const Footer: React.FC = () => {
  return (
    <Box bg='accent1' py='medium'>
      <Grid>
        <Links>
          <li>
            <a href='mailto:contact@doseapp.io'>Contact</a>
          </li>
          <li>
            <a href='mailto:press@doseapp.io'>Press</a>
          </li>
          <li>
            <Link to='/donate'>Donate</Link>
          </li>
          <li>
            <Link to='/terms-of-use'>Terms</Link>
          </li>
          <li>
            <Link to='/privacy-policy'>Privacy</Link>
          </li>
        </Links>

        <CenteredRowView>
          <Text as='p' textAlign='center' mr='tiny'>
            &copy; 2020 Dose
          </Text>
          <Box mx='tiny'>
            <a href='https://instagram.com/dosetracking'>
              <Instagram width={14} fill={colors.textAccent1} />
            </a>
          </Box>
          <Box mx='tiny'>
            <a href='https://twitter.com/dosetracking'>
              <Twitter width={14} fill={colors.textAccent1} />
            </a>
          </Box>
        </CenteredRowView>
      </Grid>
    </Box>
  )
}

const Links = styled.ul`
  list-style: none;
  text-align: center;

  li {
    display: inline-block;
    font-weight: ${fontWeights.medium};
    margin: 0 ${space.small};

    a {
      color: ${colors.text};
      text-decoration: underline;
    }
  }
`
